export default [
    {
        path: '/associate_cards',
        name: 'AssociateCards',
        component: () => import ('./AssociateCards'),
    },
    {
        path: '/associate_cards/:id',
        name: 'AssociateCard',
        component: () => import ('./AssociateCard'),
    },
    {
        path: '/associate_cards/:id/ios',
        name: 'AssociateCardIos',
        component: () => import ('./AssociateCard'),
    },
];