<template>
    <b-modal centered
             ref="setIndexModal"
             title="Potvrda"
             @cancel="resetIndexModal"
             @ok="createIndex">
        <p class="my-4">Jeste li sigurni da želite dodijeliti redni broj?</p>
        <b-form>
            <b-form-group>
                <b-form-radio-group
                        v-if="type !== 'manual'"
                        v-model="editType">
                    <b-form-radio value="auto">Automatski</b-form-radio>
                    <b-form-radio value="manual">Ručno</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
            <b-form-input v-if="editType==='manual'" v-model="manual" type="number" min="1"/>
        </b-form>
    </b-modal>
</template>

<script>
    export default {
        name: 'set-index-modal',
        props: {
            item: {
                type: Object,
                default: null
            },
            index: {
                type: String,
                default: null
            },
            type: {
                type: String,
                default: 'auto'
            }
        },
        data() {
            return {
                editType: 'auto',
                manual: null
            }
        },
        created() {
            if (this.type === 'manual') {
                this.editType = 'manual';
            }
        },
        methods: {
            show() {
              this.$refs.setIndexModal.show();
            },
            createIndex() {
                const postData = {
                    editType: this.editType,
                    indexType: this.index,
                    manual: this.manual
                };
                let self = this;
                let route = api.invoices + this.item.id;
                if (this.index === 'kpr_index') {
                    route = api.entryOrders + this.item.id + '/index';
                }
                axiOsta.post(route, postData).then(response => {
                    if (response.status === 200) {
                        self.resetIndexModal();
                        self.$parent.$emit('get-rows');
                    }
                })
            },
            resetIndexModal() {
                this.manual = null;
                this.editType = this.type;
            }
        }
    }
</script>