// Domain url
import { domain } from './.env.js';
export const apiDomain = domain + '/api/';
export const rows = '/rows';

// Auth
export const login = 'login';
export const register = 'register';
export const logout = 'logout';

// Users
export const users =  'users';
export const logged = users + '/logged';

// Clients
export const clients = 'clients/';
export const townships = 'townships/';
export const activities = 'activities/';

// Associates
export const associates = 'associates/';

// Mpc Forms
export const mpcForms = 'mpc_forms/';
export const mpcRows = 'mpc_rows/';
export const mpcFormConfirm = 'mpc_forms/confirm';

// Amortization books
export const amortizationBooks = 'amortization_books/';
export const amortizationBookRows = 'amortization_book_rows/';

// Insurance
export const insurances = 'insurances/';

// Kpr Forms
export const kprForms = 'kpr_forms/';

// Entry Orders
export const entryOrders = 'entry_orders/';
export const entryOrderConfirm = 'entry_orders/confirm/';

// Entry Order Rows
export const entryOrderRows = 'entry_order_rows/';

// Kontos
export const kontos = 'kontos/';

// Tax rows
export const taxRows = '/tax_rows/';

// EPO rows
export const epoRows = '/epo_rows/';

// Kpr Forms
export const kprRows = '/kpr_rows';

// Invoices
export const invoices = '/invoices/';
export const invoiceStatus = 'invoices/status/';

// Employees
export const employees = '/employees/';

// Document types    - kada bira u radnom nalogu je li KIF,KUF ili nista
export const documentCategories = 'document_categories/'
