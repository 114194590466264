import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Bootstrap vue
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

// Axios
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Accept'] = 'application/json';

// Use Vue cookie to store Bearer
var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

// Datepicker
import DatePicker from 'vue2-datepicker'
Vue.use(DatePicker);

// Moment
import VueMoment from 'vue-moment'
Vue.use(VueMoment);

// API
import * as api from './api.js'
window.api = api;

window.axiOsta = axios.create({
    baseURL: api.apiDomain,
    timeout: 5000
});

window.axiOsta.interceptors.request.use(
    function (config) {
        config.headers.accept = "application/json";
        config.headers.authorization = "Bearer " + VueCookie.get('api_token');

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

window.eventBus =  new Vue();

import MaraTable from '@/components/MaraTable';
import OstaTable from '@/components/OstaTable';
import RozaTable from '@/components/RozaTable';
import Navbar from '@/components/Navbar';
import SetIndexModal from '@/components/SetIndexModal';

Vue.component('mara-table', MaraTable);
Vue.component('osta-table', OstaTable);
Vue.component('roza-table', RozaTable);
Vue.component('navbar', Navbar);
Vue.component('set-index-modal', SetIndexModal);

new Vue({
        render: h=> h(App),
        router,
        store
    }
).$mount('#app');