import Vue from 'vue';
import Vuex from 'vuex';
import * as api from "./api";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        user: null,
        selected_client: null,
        associates: null,
        landscape: false
    },
    getters: {
        loggedUser: state => {
            return state.user;
        },
        selectedClient: state => {
            return state.selected_client;
        },
        associates: state => {
            return state.associates;
        },
        landscapePrint: state => {
            return state.landscape
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        selectClient(state, client) {
            state.selected_client = client;
        },
        setAssociates(state, associates) {
            state.associates = associates;
        },
        setLandscapePrint(state, landscape) {
            state.landscape = landscape;
        }
    },
    actions: {
        getUser(context) {
            axiOsta.get(api.logged).then(response => {
                if (response.status === 200) {
                    context.commit('setUser', response.data);
                }
            })
        },
        selectClient(context, id) {
            axiOsta.get(api.clients + id).then(response => {
                if (response.status === 200) {
                    context.commit('selectClient', response.data);
                }
            })
        },
        getAssociates(context, id) {
            axiOsta.post(api.clients + 'associates', {
                client_id: id
            }).then(response => {
                if (response.status === 200) {
                    context.commit('setAssociates', response.data);
                }
            })
        },
        setLandscapePrint(context, payload) {
            context.commit('setLandscapePrint', payload);
        }
    }
});
