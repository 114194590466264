export default [
    {
        path: '/clients',
        name: 'Clients',
        component: () => import ('./Clients'),
    },
    {
        path: '/clients/create',
        name: 'ClientsCreate',
        component: () => import ('./Create'),
    },
    {
        path: '/clients/:id',
        name: 'Client',
        component: () => import ('./Client'),
    },
    {
        path: '/clients/:id/edit',
        name: 'ClientsEdit',
        component: () => import ('./Edit'),
    }
];