<template>
    <div>
        <table class="table b-table table-bordered text-center align-middle" :class="{'table-striped' : !nested}">
            <thead>
            <slot name="custom-header"></slot>
            <tr>
                <th v-for="field in fields" :class="field.thClass">
                    {{field.label}}
                </th>
                <th v-if="actions" class="actions-slot">Akcije</th>
            </tr>
            <tr v-if="colIndex !== 0">
                <th v-for="(field, index) in fields" :class="field.thClass">
                    {{index + colIndex}}
                </th>
                <th v-if="actions" class="actions-slot">{{fields.length + colIndex}}</th>
            </tr>
            <slot name="custom-header2"></slot>
            <tr v-if="currentPage !== 1" class="table-border-all font-weight-normal">
                <td class="table-border-right" :colspan="prenosColspan">
                    DONOS:
                </td>
                <td v-for="prenos in prenosFields" class="text-right" :class="prenos.class" :colspan="prenos.colspan || 1">
                    <slot :name="prenos.key +'Donos'">
                        <template v-if="prenos.key">
                            {{items[prenos.key + 'Prev']}}
                        </template>
                        <template v-else>
                            {{items[prenos + 'Prev']}}
                        </template>
                    </slot>
                </td>
                <td v-if="actions" class="actions-slot"></td>
            </tr>
            </thead>
            <tbody>
            <!--<template v-if="nested">
                <template v-for="(nestItem, nestIndex) in items.data">
                    <tr v-for="(item, index) in nestItem" :class="{'table-border-top': nestIndex === stopIndex }">
                        <template v-for="field in fields">
                            <td :class="field.class"
                                v-if="!field.rowspan || index < 1"
                                :rowspan="field.rowspan && index === 0 ? nestItem.length : 1">
                                <slot :name="field.key2 ? field.key + '.' + field.key2 : field.key"
                                      :item="item" :field="field" :nestItem="nestItem">
                                    <template v-if="field.key === 'position'">
                                        <template v-if="index === 0 && nestItem">
                                            <template v-if="stopIndex === false || (nestIndex < stopIndex)">
                                                {{((currentPage - 1) * perPage) + nestIndex + 1}}
                                            </template>
                                        </template>
                                    </template>
                                    <template v-else-if="field.key2">
                                        <template v-if="item[field.key][field.key2] !== '0,00'">
                                            {{item[field.key][field.key2]}}
                                        </template>
                                    </template>
                                    <template v-else>
                                        <template v-if="item[field.key] !== '0,00'">
                                            {{item[field.key]}}
                                        </template>
                                    </template>
                                </slot>
                            </td>
                        </template>
                        <td v-if="actions && index < 1" class="actions-slot"
                            :rowspan="index === 0 ? nestItem.length : 1">
                            <div v-for="(action, key) in actions"
                                 class="action px-2"
                                 @click.stop="actionClick(key, item)"
                                 v-b-tooltip.hover :title="action.name">
                                <i v-if="action.icon" :class="action.icon"></i>
                                <b-btn v-else :variant="action.variant">{{action.name}}</b-btn>
                            </div>
                        </td>
                    </tr>
                </template>
            </template>-->
            <template>
                <tr v-for="(item, index) in items.data">
                    <td v-for="field in fields" :class="field.class">
                        <slot :name="field.key2 ? field.key + '.' + field.key2 : field.key"
                              :item="item" :field="field">
                            <template v-if="field.key === 'position'">
                                <template v-if="!indexConfirmed || (item.confirmed && indexConfirmed)">
                                    {{((currentPage - 1) * perPage) + index + 1}}
                                </template>
                            </template>
                            <template v-else-if="field.key2 && item[field.key] && item[field.key][field.key2]">
                                <template v-if="field.date">
                                    {{item[field.key] | moment("DD.MM.YYYY.")}}
                                </template>
                                <template v-else-if="item[field.key][field.key2] !== '0,00'">
                                    {{item[field.key][field.key2]}}
                                </template>
                            </template>
                            <template v-else-if="field.key && item[field.key]">
                                <template v-if="field.date">
                                    {{item[field.key] | moment("DD.MM.YYYY.")}}
                                </template>
                                <template v-else-if="item[field.key] !== '0,00'">
                                    {{item[field.key]}}
                                </template>
                            </template>
                        </slot>
                    </td>
                    <td v-if="actions" class="actions-slot">
                        <div v-if="!item[actionHide]" class="flex-center">
                            <div v-for="(action, key) in actions"
                                 class="action px-2"
                                 @click.stop="actionClick(key, item)"
                                 v-b-tooltip.hover :title="action.name">
                                <i v-if="action.icon" :class="action.icon"></i>
                                <b-btn v-else :variant="action.variant">{{action.name}}</b-btn>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
            </tbody>
            <tr class="table-border-all">
                <td class="table-border-right" :colspan="prenosColspan">
                    UKUPNO - PRENOS:
                </td>
                <td v-for="prenos in prenosFields" class="text-right" :class="prenos.class" :colspan="prenos.colspan || 1">
                    <slot :name="prenos.key +'Prenos'">
                        <template v-if="prenos.key">
                            {{items[prenos.key]}}
                        </template>
                        <template v-else>
                            {{items[prenos]}}
                        </template>
                    </slot>
                </td>
                <td v-if="actions" class="actions-slot"></td>
            </tr>
        </table>
        <b-col v-if="printPage" class="text-right print-show my-2">Stranica {{currentPage}} od {{items.last_page}}</b-col>
    </div>
</template>

<script>
    export default {
        name: 'roza-table',
        props: {
            items: {
                type: Object,
                required: true
            },
            fields: {
                type: Array,
                required: true
            },
            prenosFields: {
                type: Array
            },
            perPage: {
                type: Number,
                default: 10
            },
            currentPage: {
                type: Number,
                default: 1
            },
            actions: {
                type: Object
            },
            colIndex: {
                type: Number,
                default: 0
            },
            prenosColspan: {
                type: Number,
                default: 1
            },
            nested: {
                type: Boolean,
                default: false
            },
            order: {
                type: Boolean,
                default: false
            },
            printPage: {
                type: Boolean,
                default: false
            },
            actionHide: {
                type: String,
                default: null
            },
            indexConfirmed: {
                type: Boolean,
                default: false
            },
        },
        methods: {
            actionClick(actionKey, item) {
                this.$emit('action', actionKey, item)
            }
        }
    }
</script>