<template>
    <main class="flex-fill">
        <navbar/>
        <router-view class="main-content" :class="{'print-landscape' : landscape}"/>
    </main>
</template>

<script>
    export default {
        data() {
            return {
                hrv: {
                    days: ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'],
                    months: ['Siječanj', 'Veljača', 'Ožujak', 'Travanj', 'Svibanj', 'Lipanj', 'Srpanj', 'Kolovoz',
                        'Rujan', 'Listopad', 'Studeni', 'Prosinac'],
                    pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
                    placeholder: {
                        year: 'Odaberi godinu',
                        date: 'Odaberi datum',
                        dateRange: 'Odaberi raspon'
                    }
                },
            }
        },
        methods: {
            loadClient() {
                let selected = this.$cookie.get('selected_client');
                if (selected != null) {
                    this.$store.dispatch('selectClient', selected)
                }
            }
        },
        mounted() {
            if (!this.isAuth) {
                this.$store.dispatch('getUser');
                this.loadClient();
            }
            /*if (this.$route.path.match('mpc_forms').length > 0) {
                import('./assets/sass/landscape.scss');
            }*/
        },
        computed: {
            isAuth () {
                return this.$route.path.match('auth') || this.$route.path.match('help');
            },
            user: function () {
                return this.$store.getters.loggedUser;
            },
            selectedClient: function(){
                return this.$store.getters.selectedClient;
            },
            landscape() {
                return this.$store.getters.landscapePrint
            }
        },
        created() {
            let self = this;
            axiOsta.interceptors.response.use(
                function (response) {
                    return response;
                },
                function (error) {
                    if (error.response.status === 401) {
                        self.$cookie.delete('api_token');
                        self.$router.push('/auth/login');
                    }
                    console.log("An error ocurred -> " + error);
                    return Promise.reject(error);
                }
            );
        },
        watch: {
            selectedClient: function (val) {
                if (val.id)
                    this.$store.dispatch("getAssociates", val.id);
            }
        }
    }
</script>

<style lang="scss">
    @import "assets/sass/app";
</style>
