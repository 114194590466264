export default [
    {
        path: '/mpc_forms',
        name: 'MpcForms',
        component: () => import ('./MpcForms'),
    },
    {
        path: '/mpc_forms/create',
        name: 'MpcFormsCreate',
        component: () => import ('./Create'),
    },
    {
        path: '/mpc_forms/:id',
        name: 'MpcForm',
        component: () => import ('./MpcForm'),
    },
    {
        path: '/mpc_forms/:id/edit',
        name: 'MpcFormsEdit',
        component: () => import ('./Edit'),
    }
];