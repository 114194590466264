import Vue from 'vue'
import Router from 'vue-router'

import activitiesRoutes from '@/views/activities/router';
import amortizationBooksRoutes from '@/views/amortizationBooks/router';
import associateCardsRoutes from '@/views/associateCards/router';
import associatesRoutes from '@/views/associates/router';
import authRoutes from '@/views/auth/router';
import clientsRoutes from '@/views/clients/router';
import entryOrdersRoutes from '@/views/entryOrders/router';
import insurancesRoutes from '@/views/insurances/router';
import mpcFormsRoutes from '@/views/mpcForms/router';

import Support from '@/views/Support'
import Documentation from '@/views/Documentation'
import KontniPlan from '@/views/KontniPlan'

import PaymentOrderCreate from '@/views/paymentOrders/Create'

Vue.use(Router);

export default new Router({
    linkActiveClass: 'open active',
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        {
            path: '/',
            name: 'Home',
            redirect: '/clients'
        },
        {
            path: '/help/support',
            name: 'Support',
            component: Support
        },
        {
            path: '/help/documentation',
            name: 'Documentation',
            component: Documentation
        },
        {
            path: '/kontni_plan',
            name: 'KontniPlan',
            component: KontniPlan
        },
        // Payment Orders
        {
            path: '/payment_orders/create',
            name: 'PaymentOrderCreate',
            component: PaymentOrderCreate
        },
        // Routes
        ...authRoutes,
        ...activitiesRoutes,
        ...amortizationBooksRoutes,
        ...associateCardsRoutes,
        ...associatesRoutes,
        ...clientsRoutes,
        ...entryOrdersRoutes,
        ...insurancesRoutes,
        ...mpcFormsRoutes,
        // KPR
        {
            path: '/kpr',
            name: 'KPR',
            component: () => import ('./views/kprForms/KprForm')
        },
        // KUF i KIF form
        {
            path: '/kuf',
            redirect: '/kuf/a'
        },
        {
            path: '/kuf/:variant',
            name: 'KufForm',
            component: () => import ('./views/kufForms/KufForm')
        },
        {
            path: '/kif',
            redirect: '/kif/a'
        },
        {
            path: '/kif/:variant',
            name: 'KifForm',
            component: () => import ('./views/kifForms/KifForm'),
        },
        // EPO
        {
            path: '/epo',
            name: 'EPO',
            component: () => import ('./views/epoForms/epoForm')
        },
        // Workpower
        {
            path: '/wage_specification/create',
            name: 'WageSpecificationCreate',
            component: () => import ('./views/wageSpecification/Create'),
        },
        {
            path: '/employees',
            name: 'Employees',
            component: () => import ('./views/employees/Employees'),
        },
        {
            path: '/employees/create',
            name: 'CreateEmployee',
            component: () => import ('./views/employees/Create'),
        },
        {
            path: '/employees/:id',
            name: 'Employee',
            component: () => import ('./views/employees/Employee'),
        },
        {
            path: '/employees/:id/edit',
            name: 'EmployeesEdit',
            component: () => import ('./views/employees/Edit'),
        },
        {
            path: '/payroll/',
            name: 'Payroll',
            component: () => import ('./views/payroll/Payroll'),
        },
        {
            path: '/yearly_payroll/',
            name: 'YearlyPayroll',
            component: () => import ('./views/yearlyPayroll/YearlyPayroll'),
        },
        {
            path: '/monthly_payrolls/',
            name: 'MonthlyPayrolls',
            component: () => import ('./views/monthlyPayroll/MonthlyPayrolls'),
        },
        {
            path: '/monthly_payroll/',
            name: 'MonthlyPayroll',
            component: () => import ('./views/monthlyPayroll/MonthlyPayroll'),
        },
        {
            path: '/single_monthly_payroll/',
            name: 'SingleMonthlyPayroll',
            component: () => import ('./views/monthlyPayroll/SingleMonthlyPayroll'),
        },
        {
            path: '/payslip/',
            name: 'Payslip',
            component: () => import ('./views/payslip/Payslip'),
        },
    ]
})
