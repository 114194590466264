export default [
    {
        path: '/entry_orders',
        name: 'EntryOrders',
        component: () => import ('./EntryOrders'),
    },
    {
        path: '/entry_orders/create',
        name: 'EntryOrdersCreate',
        component: () => import ('./Create'),
    },
    {
        path: '/entry_orders/:id',
        name: 'EntryOrder',
        component: () => import ('./EntryOrder'),
    },
    {
        path: '/entry_orders/:id/edit',
        name: 'EntryOrderEdit',
        component: () => import ('./Edit'),
    },
];