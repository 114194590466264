export default [
    {
        path: '/amortization_books',
        name: 'AmortizationBooks',
        component: () => import ('./AmortizationBooks'),
    },
    {
        path: '/amortization_books/create',
        name: 'AmortizationBooksCreate',
        component: () => import ('./Create'),
    },
    {
        path: '/amortization_books/:id',
        name: 'AmortizationBook',
        component: () => import ('./AmortizationBook'),
    },
    {
        path: '/amortization_books/:id/edit',
        name: 'AmortizationBooksEdit',
        component: () => import ('./Edit'),
    },
];