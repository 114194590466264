<template>
    <b-container v-if="selectedClient">
        <b-row class="flex-center my-2">
            <b-col>
                <b-form v-on:submit.prevent="createInsurance">
                    <table class="table b-table table-striped table-hover table-bordered text-center">
                        <thead>
                        <tr class="align-middle">
                            <th colspan="4">
                                <h4>Platni nalog</h4>
                            </th>
                        </tr>
                        <tr class="align-middle">
                            <td colspan="2">Uplatio je: (ime, adresa, telefon)
                                <b-form-input id="payer" type="text" size="sm" name="payer" required></b-form-input>
                            </td>
                            <td colspan="2">Račun pošiljatelja: <b-form-input id="payer_acc" type="text" size="sm" name="payer_acc" required></b-form-input>
                            </td>
                        </tr>
                        <tr class="align-middle">
                            <td colspan="2">Svrha doznake: <b-form-input id="cause" type="text" size="sm" name="cause" required></b-form-input>
                            </td>
                            <td colspan="2">Račun primatelja / primaoca: <b-form-input id="payee_account" type="text" size="sm" name="payee_account" required></b-form-input>
                            </td>
                        </tr>
                        <tr class="align-middle">
                            <td colspan="2">Primatelj / primalac: <b-form-input id="payee" type="text" size="sm" name="payee" required></b-form-input>
                            </td>
                            <td colspan="1"><b-form-group
                                    class="w-100"
                                    horizontal
                                    :label-cols="4"
                                    breakpoint="md"
                                    label="KM "
                                    label-for="amount">
                                <b-form-input id="amount" type="number" size="sm" name="amount" required></b-form-input>
                            </b-form-group>
                            </td>
                            <td>
                                <b-form-group
                                        stacked
                                        class="w-100">
                                    <b-form-radio-group
                                            id="important"
                                            name="important">
                                        <b-form-radio value="1">HITNO</b-form-radio><br/>
                                    </b-form-radio-group>
                                </b-form-group>
                            </td>
                        </tr>

                        <tr class="align-middle">
                            <th colspan="4">
                                Samo za uplate javnih prihoda
                            </th>
                        </tr>
                        <tr class="align-middle">
                            <td colspan="2">Broj poreznog obveznika
                                <b-form-input id="pdv_num" type="text" size="sm" name="pdv_num" required></b-form-input>
                            </td>
                            <td colspan="2">Vrsta uplate
                                <b-form-input id="payment_type" type="text" size="sm" name="payment_type" required></b-form-input>
                            </td>
                        </tr>
                        <tr class="align-middle">
                            <td colspan="2">Vrsta prihoda
                                <b-form-input id="income_type" type="text" size="sm" name="income_type" required></b-form-input>
                            </td>
                            <td colspan="2">Datum od / do
                                <b-form-group
                                        class="w-100"
                                        horizontal
                                        :label-cols="4"
                                        breakpoint="md"
                                        label-for="pay_date">
                                    <b-form-input id="pay_date_from" type="date" size="sm" name="pay_date_from" required></b-form-input>
                                </b-form-group>
                                <b-form-group
                                        class="w-100"
                                        horizontal
                                        :label-cols="4"
                                        breakpoint="md"
                                        label-for="pay_date">
                                    <b-form-input id="pay_date_to" type="date" size="sm" name="pay_date_to" required></b-form-input>
                                </b-form-group>
                            </td>
                        </tr>

                        <tr class="align-middle">
                            <td colspan="1">Općina
                                <b-form-input id="county" type="text" size="sm" name="county" required></b-form-input>
                            </td>
                            <td colspan="2">Programska / budžetska organizacija <b-form-input id="organisation" type="text" size="sm" name="organisation" required></b-form-input>
                            </td>
                            <td colspan="1">Poziv na broj <b-form-input id="phone_num" type="text" size="sm" name="phone_num" required></b-form-input>
                            </td>
                        </tr>

                        <tr class="align-middle">
                            <td colspan="2">Datum uplate
                                <b-form-group
                                        class="w-100"
                                        horizontal
                                        :label-cols="4"
                                        breakpoint="md"
                                        label-for="pay_date">
                                    <b-form-input id="pay_date_to" type="date" size="sm" name="pay_date_to" required></b-form-input>
                                </b-form-group>
                            </td>
                            <td colspan="2">Mjesto uplate <b-form-input id="payment_place" type="text" size="sm" name="payment_place" required></b-form-input>
                            </td>
                        </tr>
                        </thead>
                    </table>
                </b-form>
            </b-col>
        </b-row>

        <b-row class="flex-center no-border">
            <b-btn type="submit" variant="primary" class="my-4 w-50">Ispiši</b-btn>
        </b-row>

    </b-container>
</template>

<script>
    export default {
        computed: {
            selectedClient: function () {
                return this.$store.getters.selectedClient;
            }
        },
    }
</script>
