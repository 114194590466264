export default [
    {
        path: '/associates',
        name: 'Associates',
        component: () => import ('./Associates'),
    },
    {
        path: '/associates/create',
        name: 'AssociateCreate',
        component: () => import ('./Create'),
    },
    {
        path: '/associates/:id',
        name: 'Associate',
        component: () => import ('./Associate'),
    },
    {
        path: '/associates/:id/edit',
        name: 'AssociateEdit',
        component: () => import ('./Edit'),
    }
];