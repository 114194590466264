<template>
    <table v-if="item" class="table b-table table-striped table-hover table-bordered text-left align-middle">
        <thead>
        <tr>
            <td><b class="text-capitalize">{{label}}:</b> {{item.name}}
            </td>
        </tr>
        <tr>
            <td><b>Adresa {{label}}a:</b> {{item.address}}
            </td>
        </tr>
        <tr>
            <td><b>ID broj {{label}}a:</b> {{item.id_number}}
            </td>
        </tr>
        <tr>
            <td><b>PDV broj {{label}}a:</b> {{item.pdv_number}}
            </td>
        </tr>
        </thead>
    </table>
</template>

<script>
    export default {
        name: 'mara-table',
        props: {
            item: {
                type: Object
            }
        },
        computed: {
            label: function () {
                let label = '';
                switch (this.item.type) {
                    case 2:
                        label = "dobavljač";
                        break;
                    case 1:
                        label = "kupac";
                        break;
                    default:
                        label = 'klijent'
                }
                return label;
            }
        }
    }
</script>