<template>
    <b-navbar id="myNavbar" toggleable="md" type="dark" variant="info" class="px-4">

        <b-navbar-toggle target="nav_collapse"/>

        <b-collapse is-nav id="nav_collapse" ref="nav_collapse">
            <b-navbar-nav>
                <router-link v-if="$parent.isAuth" to="/">
                    <b-img :src="require('../assets/img/logo.png')" fluid alt="Knjigovođa" />
                </router-link>
                <b-nav-item-dropdown v-else class="sidebar mr-5">
                    <template slot="button-content">
                        <b-img :src="require('../assets/img/logo.png')" fluid alt="Knjigovođa" />
                    </template>

                    <template v-for="(item, index) in navItems" v-if="($parent.selectedClient || item.show)">
                        <li class="sidebar-link">
                            <router-link :to="item.href"
                                         v-b-toggle="'collapse' + item.title"
                                         class="d-flex justify-content-between">
                                <div>
                                    {{ item.title }}
                                </div>
                            </router-link>
                            <b-collapse accordion="my-accordion" :id="'collapse' + item.title">
                                <ul v-if="item.submenuItems" class="list-unstyled">
                                    <li v-for="sub in item.submenuItems" class="submenu-link">
                                        <router-link
                                                :to="sub.href"
                                                v-b-toggle="'collapse' + sub.title"
                                                class="ml-2">
                                            {{ sub.title }}
                                        </router-link>
                                        <b-collapse accordion="my-accordion2" :id="'collapse' + sub.title">
                                            <ul v-if="sub.submenuItems" class="list-unstyled">
                                                <li v-for="subsub in sub.submenuItems" class="submenu-link">
                                                    <router-link
                                                            :to="subsub.href"
                                                            class="ml-3">
                                                        {{ subsub.title }}
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </b-collapse>
                                    </li>
                                </ul>
                            </b-collapse>
                        </li>
                        <div v-if="index < navItems.length - 1" class="separator"></div>
                    </template>
                </b-nav-item-dropdown>

                <li class="nav-item d-flex align-items-center" v-if="$parent.selectedClient && !$parent.isAuth">
                    <router-link to="/clients" class="light-link">
                        <p class="m-0">Odabrani klijent: {{$parent.selectedClient.name}}</p>
                    </router-link>
                </li>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-item class="flex-center mr-2">
                    <date-picker format="YYYY"
                                 v-model="year"
                                 value-type="format"
                                 :lang="$parent.hrv"
                                 class="w-100"
                                 type="year"
                                 id="year">
                    </date-picker>
                </b-nav-item>

                <b-nav-item @click="rotatePrint" class="flex-center mr-2">
                    <span v-if="landscape" class="fa fa-2x fa-image"/>
                    <span v-else class="fa fa-2x fa-portrait"/>
                </b-nav-item>

                <b-nav-item @click="print" class="flex-center mr-2">
                    <span class="fa fa-2x fa-print"/>
                </b-nav-item>

                <b-nav-item-dropdown right no-caret class="mr-2">
                    <template slot="button-content">
                        <em><b-img :src="require('../assets/img/info.png')" fluid alt=""/></em>
                    </template>
                    <router-link class="dropdown-item" to='/help/support'>Podrška</router-link>
                    <router-link class="dropdown-item" to='/help/documentation'>Dokumentacija</router-link>
                </b-nav-item-dropdown>

                <b-nav-item-dropdown v-if="!$parent.isAuth" right no-caret>
                    <template slot="button-content">
                        <em><b-img :src="require('../assets/img/profile.png')" fluid alt=""/></em>
                    </template>
                    <b-dropdown-item @click="logout">Odjava</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>

        </b-collapse>
    </b-navbar>
</template>

<script>
    export default {
        name: 'navbar',
        data() {
            return {
                year: null,
                navItems: [
                    {
                        title: "Klijenti",
                        href: "/clients",
                        show: true
                    },
                    {
                        title: "Suradnici",
                        href: "/associates",
                    },
                    {
                        title: "Porezne knjige",
                        href: "/entry_orders",
                        submenuItems: [
                            {
                                title: "Nalozi za knjiženje",
                                href: "/entry_orders",
                            },
                            {
                                title: "Knjiga prihoda i rashoda",
                                href:"/kpr"
                            },
                            {
                                title: "Amortizacije",
                                href:"/amortization_books"
                            },
                            {
                                title: "Knjiga izlaznih faktura (KIF)",
                                href: "/kif",
                                submenuItems: [
                                    {
                                        title: "KIF A",
                                        href:"/kif/a"
                                    },
                                    {
                                        title: "KIF B",
                                        href:"/kif/b"
                                    },
                                ]
                            },
                            {
                                title: "Knjiga ulaznih faktura (KUF)",
                                href: "/kuf",
                                submenuItems: [
                                    {
                                        title: "KUF A",
                                        href:"/kuf/a"
                                    },
                                    {
                                        title: "KUF B",
                                        href:"/kuf/b"
                                    },
                                ]
                            },
                            {
                                title: "Evidencija potraživanja",
                                href: "/epo",
                            },
                            {
                                title: "Kartice kupca/dobavljača",
                                href: "/associate_cards",
                            }
                        ]
                    },
                    {
                        title: "Kalkulacije MPC u UP",
                        href: "/mpc_forms",
                    },
                    /* {
                         title: "Salda / Konti",
                         href: "/konto",
                         submenuItems: [
                             {
                                 title: "PDV Prijava",
                                 href:"/vat_returns"
                             },
                             {
                                 title: "Dodatak PDV Prijavi",
                                 href:"/vat_return_additions/create"
                             }

                         ]
                     },*/
                    {
                        title: "Radna snaga",
                        href: "/employees",
                        submenuItems: [
                            {
                                title: "Radnici",
                                href:"/employees"
                            },
                            {
                                title: "Specifikacija uz isplatu plaća",
                                href:"/wage_specification/create"
                            },
                            {
                                title: "Obračunski list plaća",
                                href:"/payroll/"
                            },
                            {
                                title: "Godišnje izvješće",
                                href:"/yearly_payroll/"
                            },
                            {
                                title: "Mjesečna izvješća",
                                href:"/monthly_payrolls/"
                            },
                            {
                                title: "Mjesečno izvješće",
                                href:"/monthly_payroll/"
                            },
                            {
                                title: "Platna lista",
                                href:"/payslip/"
                            },
                            {
                                title: "Osiguranje poduzetnika",
                                href: "/insurances",
                            },
                        ]
                    },
                    {
                        title: "Kontni Plan",
                        href: "/kontni_plan",
                    },
                    {
                        title: "Platni nalozi (virmani)",
                        href: "/payment_orders/create",
                    },
                    {
                        title: "Ostalo",
                        href: "/activities",
                        submenuItems: [
                            {
                                title: "Djelatnosti",
                                href:"/activities"
                            }
                        ]
                    },
                ]
            }
        },
        computed: {
            landscape() {
                return this.$store.getters.landscapePrint
            }
        },
        methods: {
            logout() {
                axiOsta.post(api.logout).then(response => {
                    if (response.status === 200) {
                        this.$cookie.delete('api_token');
                        this.$router.push('/auth/login');
                    }
                })
            },
            rotatePrint() {
                this.$store.dispatch('setLandscapePrint', !this.landscape);
            },
            print() {
                window.print();
            }
        },
        mounted() {
            this.year = this.$cookie.get('ezint-year');
        },
        watch: {
            'year': function (newYear) {
                if (newYear == null || newYear === '')
                    this.year = new Date().getFullYear().toString();
                this.$cookie.set("ezint-year", newYear);
                eventBus.$emit('year-changed', newYear);
            }
        }
    }
</script>

<style lang="scss">

    #myNavbar {
        min-height: 70px;
        background-color: #424242 !important;
        -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.44);
        -moz-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.44);
        box-shadow: 0 4px 5px 0 rgba(0,0,0,0.44);
        img {
            max-height: 40px;
        }
    }

    .sidebar {
        .dropdown-menu {
            background: #424242;
            padding: 8px 16px;
        }
    }

    .sidebar-link {
        font-size: 1em !important;
        min-width: 300px;
    }

    .submenu-link {
        font-size: 0.9em !important;
    }

    .sidebar-link, .submenu-link {
        background: #424242;
        margin: 8px 0;
        a {
            padding: 10px 10px;
            color: #FFF!important;
        }
        a.active {
            font-weight: 700;
            text-decoration: underline;
        }
    }

    .separator{
        height: 2px;
        background: rgba(255,255,255,.4);
        margin: 0 10px;
    }

</style>

