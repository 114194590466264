export default [
    {
        path: '/auth/login',
        component: () => import ('./Login.vue'),
        name: 'Login'
    },
    /*{
        path: '/auth/register',
        component: () => import ('./Register.vue'),
        name: 'Register'
    },*/
];