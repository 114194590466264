export default [
    {
        path: '/insurances',
        name: 'Insurances',
        component: () => import ('./Insurances'),
    },
    {
        path: '/insurances/create',
        name: 'InsurancesCreate',
        component: () => import ('./Create'),
    },
    {
        path: '/insurances/:id',
        name: 'Insurance',
        component: () => import ('./Insurance'),
    },
    {
        path: '/insurances/:id/edit',
        name: 'InsurancesEdit',
        component: () => import ('./Edit'),
    }
];